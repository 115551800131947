import React, { FC } from "react"
import { Tab, TabbedShowLayout, useRecordContext } from "react-admin"
import { SurveyInstanceRecord } from "../../../types"
import { SurveyResultsField } from "../SurveyResultsField"
import { ByTeamResultsTabContent } from "./ByTeamResultsTabContent"
import { IndividualResultsTabContent } from "./IndividualResultsTabContent"

export const ShowTabs: FC = () => {
  const r = useRecordContext<SurveyInstanceRecord>()

  return (
    <TabbedShowLayout>
      <Tab label="Results">
        <SurveyResultsField />
      </Tab>
      {r?.id && !r?.isAnonymous && (
        <Tab label="Individual Results">
          <IndividualResultsTabContent />
        </Tab>
      )}
      {r?.id && !r?.teamId && (
        <Tab label="By Team">
          <ByTeamResultsTabContent />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}
