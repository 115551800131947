import React, { FC, useContext } from "react"
import { NumberAnswersContext } from "../../context"
import { arrayRange } from "../../scale-survey-parts/inputs/input-common"

type InputMethodTypes = "slider" | "numeric" | "likert"

type SurveyInputProps = {
  type: InputMethodTypes
  questionId: number
  lowerLimit: number
  upperLimit: number
  InputElement: React.FC<any>
}

const SurveyInput: FC<SurveyInputProps> = ({
  type,
  questionId,
  lowerLimit,
  upperLimit,
  InputElement,
  ...rest
}) => {
  const range = arrayRange(lowerLimit, upperLimit, 1)
  const { updateAnswer, getAnswer } = useContext(NumberAnswersContext)
  const selectedValue = getAnswer(questionId)

  const onClick = (qId: number, value: number) => updateAnswer(qId, value)

  switch (type) {
    case "slider":
      return (
        <InputElement
          questionId={questionId}
          onClick={onClick}
          selectedValue={selectedValue}
          {...rest}
        />
      )
    case "numeric":
    case "likert":
    default:
      return (
        <>
          {range.map((x, index) => (
            <InputElement
              x={x}
              index={index}
              questionId={questionId}
              onClick={onClick}
              selectedValue={selectedValue}
              {...rest}
            />
          ))}
        </>
      )
  }
}

export default SurveyInput
