import { Box, Typography } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { BooleanInput, Labeled, ReferenceInput, required, TextInput, useGetOne } from "react-admin"
import { useFormContext } from "react-hook-form"
import { resourcesMap, surveyConfigs } from "../../constants"
import { parseSurveyMapId } from "../../utils"
import { isSmallScreen } from "../../utils/common-utils"
import { AutocompleteInput, DateTimeInput, ReferenceField, SimpleForm, TextField } from "../_design"
import { MembersRefArrayInput } from "../teams/inputs/MembersRefArrayInput"
import { SurveyTypeChipField } from "./fields/SurveyTypeChipField"

const getOptionText = (r: { mapId: string; name: string }) => (
  <>
    <SurveyTypeChipField surveyMapId={r.mapId} />
    {r.name}
  </>
)

const getDefaultDates = () => {
  const startDate = new Date(new Date().setHours(0, 0, 0, 0))
  const endDate = new Date(new Date().setDate(new Date().getDate() + 7))
  // Set time to 23:59:59
  endDate.setHours(23, 59, 59, 999)

  return { startDate, endDate }
}

const getInputWidth = () => (isSmallScreen() ? "100%" : "29%")

const SurveyInstanceForm: FC = (): JSX.Element => {
  const { startDate, endDate } = getDefaultDates()
  const [selectedMapId, setSelectedMapId] = useState()

  return (
    <SimpleForm>
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Typography variant="h6" gutterBottom>
          Survey Instance Details
        </Typography>
      </Box>

      <Labeled label="Company">
        <ReferenceField source="companyId" reference={resourcesMap.companies.basePath}>
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <Labeled label="Team">
        <ReferenceField source="teamId" reference={resourcesMap.teams.basePath}>
          <TextField source="name" />
        </ReferenceField>
      </Labeled>

      <Labeled label="Members">
        <MembersRefArrayInput />
      </Labeled>

      <Box display={{ xs: "block", sm: "flex", width: getInputWidth() }}>
        <TextInput source="name" label="Survey Name" fullWidth validate={required()} />
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: getInputWidth() }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <DateTimeInput
            defaultValue={startDate}
            source="startDate"
            fullWidth
            validate={required()}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <DateTimeInput defaultValue={endDate} source="endDate" fullWidth validate={required()} />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: getInputWidth() }}>
        <ReferenceInput
          source="surveyMapId"
          reference={resourcesMap.allSurveys.basePath}
          perPage={10000}
        >
          <AutocompleteInput
            optionText={getOptionText}
            optionValue="mapId"
            inputText={(r) => r.name}
            validate={required()}
            onChange={(id) => setSelectedMapId(id)}
            fullWidth
          />
        </ReferenceInput>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: getInputWidth() }}>
        <TextInput source="title" label="Survey Title (public)" fullWidth validate={required()} />
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <IntroConnectedInput mapId={selectedMapId} />
      </Box>

      <BooleanInput label="Anonymous" source="isAnonymous" />
    </SimpleForm>
  )
}

const useGetSurveyIntro = (mapId?: string) => {
  const parsedMapId = parseSurveyMapId(mapId ?? "")
  const surveyConfig = surveyConfigs[parsedMapId?.typeId]

  const { data, isError, isFetched } = useGetOne(
    surveyConfig?.resource,
    { id: parsedMapId?.surveyId },
    { enabled: !!mapId }
  )

  if (isError || !isFetched) return null

  return data.intro
}

const IntroConnectedInput = ({ mapId }: { mapId?: string }) => {
  const intro = useGetSurveyIntro(mapId)
  const { setValue } = useFormContext()

  useEffect(() => {
    if (intro) {
      setValue("intro", intro)
    }
  }, [intro, setValue])

  return <TextInput source="intro" fullWidth multiline />
}

export default SurveyInstanceForm
