import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import Button from "@mui/material/Button"
import React from "react"
import { useRecordContext } from "react-admin"
import { Link } from "react-router-dom"
import { SurveyInstanceRecord } from "../../../types"
import { surveyTempStorage } from "../../../utils/common-utils"

const TestSurveyButton = (): JSX.Element => {
  const record = useRecordContext<SurveyInstanceRecord>()
  return (
    <Button
      component={Link}
      color="primary"
      to={{ pathname: `/participate/${record?.slug}/test` }}
      startIcon={<PlayCircleOutlineIcon />}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        surveyTempStorage.deleteAll()
      }}
    >
      Test
    </Button>
  )
}

export default TestSurveyButton
