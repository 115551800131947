import { useEffect, useState } from "react"
import { httpGet } from "../../../ra-providers/dataProvider"

type Data = {
  type: number
  survey: any
  title: string
}

type MyError = Error & {
  body: string
  status: number
}

const getSurveyAsync = async (
  surveySlug: string,
  userSlug: string,
  token: string,
  successCallback: (data: any) => void,
  errorCallback: (error: MyError) => void
) => {
  httpGet(`/Participations/${surveySlug}/${userSlug}/?token=${token}`, {
    onFailure: errorCallback,
    onSuccess: successCallback,
  })
}

type HookState = {
  loading: boolean
  data?: Data
  error: MyError | null
}

export const useGetSurvey = (surveySlug: string, userSlug: string, token: string) => {
  const [{ loading, data, error }, setResponse] = useState<HookState>({
    loading: true,
    data: undefined,
    error: null,
  })

  useEffect(() => {
    getSurveyAsync(
      surveySlug,
      userSlug,
      token,
      (data) => setResponse({ loading: false, data: data, error: null }),
      (error) => setResponse({ loading: false, data: undefined, error: error })
    )
  }, [surveySlug, userSlug])

  return { loading, data, error }
}
