import React, { FC } from "react"
import { FunctionField, useRecordContext } from "react-admin"
import { Chapter } from "../../types"
import { Datagrid } from "../_design"
import PolarAreaChart from "./charts/polar-area-chart/PolarAreaChart"
import StackedBarChart from "./charts/stacked-bar-chart/StackedBarChart"

const LikertResults = ({ fullRecord }: any) => {
  const record = useRecordContext()

  return (
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="chapter"
        render={(partialRecord: any) =>
          `${record["chapters"].findIndex((chapter: any) => chapter.id === partialRecord.id) + 1}. ${partialRecord.name}`
        }
      />
      <StackedBarChart
        getLabels={(r) => (r as Chapter).questions.map((q) => q.text ?? "")}
        fullRecord={fullRecord}
      />
    </Datagrid>
  )
}

const NumericResults = () => {
  const record = useRecordContext()

  return (
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="chapter"
        render={(partialRecord: any) =>
          `${record["chapters"].findIndex((chapter: any) => chapter.id === partialRecord.id) + 1}. ${partialRecord.name}`
        }
      />
      <PolarAreaChart
        getLabels={(r) => (r as Chapter).questions.map((q) => q.text ?? "")}
        upperLimit={record.upperLimit}
        lowerLimit={record.lowerLimit}
        getIndex={(r) => record["chapters"].findIndex((ch: Chapter) => ch.id === r.id)}
      />
    </Datagrid>
  )
}

const ScaleResults: FC = () => {
  const fullRecord = useRecordContext()

  return fullRecord.scaleType === "Likert" ? (
    <LikertResults fullRecord={fullRecord} />
  ) : (
    <NumericResults />
  )
}

export default ScaleResults
