import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { email, required } from "react-admin"
import { ArrayInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "../_design"

const memberFieldStyle = { flexBasis: "45%" }

// Not complete data type, only for validation purposes
type CompanyFormData = { members: { email: string }[] }

/**
 * Field-level validation method of the uniqueness of an email across the whole form
 * @param value
 * @param allValues
 * @returns
 */
const emailUniquenessValidation = (value: string, allValues: CompanyFormData) => {
  // Create a normalized array of all emails in the current form state
  const allMembersEmails = allValues.members.map((m) => m.email) ?? []

  // Remove the first (original) instance of the field's value
  const index = allMembersEmails.indexOf(value)
  if (index !== -1) {
    allMembersEmails.splice(index, 1)
  }

  // Check if it still occurs in the list of values
  if (allMembersEmails.includes(value)) {
    return "Email duplicates other email in the form!"
  }

  return undefined
}

const validateEmail = [email(), emailUniquenessValidation, required()]

const CompaniesForm: FC = () => {
  return (
    <SimpleForm>
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Typography variant="h6" gutterBottom>
          Company Details
        </Typography>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="name" label="Company Name" fullWidth validate={required()} />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            fullWidth
            source="industry"
            choices={[
              { id: 1, name: "Banking" },
              { id: 2, name: "Retail" },
              { id: 3, name: "Energy" },
              { id: 4, name: "IT" },
              { id: 5, name: "Finance" },
            ]}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <TextInput source="notes" multiline fullWidth />
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Typography variant="h6" gutterBottom>
          Employees
        </Typography>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "50%" }}>
        <ArrayInput source="members" label="Members">
          <SimpleFormIterator
            fullWidth
            inline
            getItemLabel={(index) => `${index + 1}.`}
            disableReordering
          >
            <TextInput
              source="firstName"
              label="First Name"
              validate={required()}
              sx={memberFieldStyle}
            />
            <TextInput
              source="lastName"
              label="Last Name"
              validate={required()}
              sx={memberFieldStyle}
            />
            <TextInput
              label="Email"
              source="email"
              validate={validateEmail}
              sx={memberFieldStyle}
            />
            <TextInput source="jobTitle" label="Job Title" sx={memberFieldStyle} />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </SimpleForm>
  )
}

export default CompaniesForm
