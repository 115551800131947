import EmailIcon from "@mui/icons-material/Email"
import GetAppIcon from "@mui/icons-material/GetApp"
import Grid from "@mui/material/Grid"
import React, { FC } from "react"
import {
  Button,
  ChipField,
  EditButton,
  Labeled,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TopToolbar,
  useNotify,
  useRecordContext,
} from "react-admin"
import { resourcesMap } from "../../constants"
import { surveyConfigs } from "../../constants/survey.constants"
import { notificationService } from "../../services"
import { SurveyInstanceRecord } from "../../types"
import { parseSurveyMapId } from "../../utils"
import {
  AsyncButton,
  FunctionField,
  Show,
  ShowSplitter,
  SimpleShowLayout,
  TextField,
} from "../_design"
import { SurveyTypeChipField } from "./fields/SurveyTypeChipField"
import { RemindMemberInput } from "./RemindMemberInput"
import { ShowTabs } from "./show-tabs/ShowTabs"
import { exportMultipleChartsToPdf } from "./toolbar"
import exportPPTX from "./toolbar/export-pptx/exportPPTX"

const ToolbarActions: FC = () => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const notify = useNotify()

  const remindAllInstanceMembers = async () => {
    await notificationService.remindAllInstanceMembers(record?.id ?? -1, {
      onSuccess: (res) => {
        notify(res ?? "Reminder sent successfully", { type: "success" })
      },
      onFailure: (error) => {
        notify(error.body ?? "Error sending reminder", { type: "error" })
      },
    })
  }

  return (
    <TopToolbar>
      <EditButton />
      <AsyncButton
        dialogTitle="Sending email reminders to all members"
        httpCall={remindAllInstanceMembers}
        startIcon={<EmailIcon />}
        label="Remind Everyone"
      />
      <Button
        onClick={() => exportPPTX(record)}
        variant="outlined"
        label="PPTX"
        startIcon={<GetAppIcon />}
      />
      <Button
        onClick={() => exportMultipleChartsToPdf(record)}
        variant="outlined"
        label="PDF"
        startIcon={<GetAppIcon />}
      />
    </TopToolbar>
  )
}

const SurveyInstanceShow: FC = () => {
  return (
    <Show component="div" actions={<ToolbarActions />}>
      <ShowSplitter
        leftSideProps={{
          md: 12,
        }}
        rightSideProps={{
          md: 12,
        }}
        leftSide={
          <Grid container>
            <Grid item xs={12} md={6}>
              <SimpleShowLayout>
                <TextField source="name" label="Survey Name" />
                <TextField source="title" />
                <FunctionField
                  source="type"
                  render={(r: SurveyInstanceRecord) => (
                    <SurveyTypeChipField surveyMapId={r?.surveyMapId} />
                  )}
                />
                <FunctionField
                  label="Template"
                  render={(r: SurveyInstanceRecord) => {
                    const { typeId, surveyId } = parseSurveyMapId(r?.surveyMapId)
                    const { resource } = surveyConfigs[typeId]

                    return (
                      <ReferenceField
                        source="surveyId"
                        record={{ id: 0, surveyId }}
                        reference={resource}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    )
                  }}
                />
                <ReferenceField
                  source="companyId"
                  reference={resourcesMap.companies.basePath}
                  link="show"
                >
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceField
                  source="teamId"
                  reference={resourcesMap.teams.basePath}
                  link="show"
                  emptyText="No team "
                >
                  <TextField source="name" />
                </ReferenceField>
              </SimpleShowLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <SimpleShowLayout>
                {/* Members */}
                <ReferenceArrayField
                  source="membersIds"
                  reference={resourcesMap.employees.basePath}
                >
                  <SingleFieldList linkType={false}>
                    <ChipField source="fullName" size="small" />
                  </SingleFieldList>
                </ReferenceArrayField>

                {/* Reminder Input*/}
                <Labeled>
                  {/* The source is used only because of the Labeled component */}
                  <RemindMemberInput source="Send Personal Reminders" />
                </Labeled>
              </SimpleShowLayout>
            </Grid>
          </Grid>
        }
        rightSide={<ShowTabs />}
      />
    </Show>
  )
}

export default SurveyInstanceShow
