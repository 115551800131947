import React, {
  Context,
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useSearchParams } from "react-router-dom"
import { Answers, OnCompleteProps, Step } from "../../../types"
import { scrollToTop, surveyTempStorage } from "../../../utils/common-utils"
import { ParticipationContext } from "../../participation/ParticipationContext"
import { GenericAnswersContext } from "../../participation/context"

type WizardContextProps = {
  steps: Step[]
  activeStep: number
  activeStepId: string
  previousStep: number
  skipped: Set<unknown> | null
  errorMessage: string
  completed: boolean
  errorOnComplete: boolean
  isToCAndGDPRChecked: boolean
  setIsToCAndGDPRChecked: Dispatch<SetStateAction<boolean>>
  handleBack: () => void
  handleNext: () => void
  handleSkip: () => void
  handleReset: () => void
  isStepSkipped: (step: number) => boolean
  isStepOptional: (step: number) => boolean
  getActiveStepId: () => string | undefined
}

export const WizardContext = createContext<WizardContextProps>({
  steps: [],
  activeStep: 0,
  activeStepId: "",
  previousStep: -1,
  skipped: null,
  errorMessage: "",
  completed: false,
  errorOnComplete: false,
  isToCAndGDPRChecked: false,
  setIsToCAndGDPRChecked: () => {},
  handleBack: () => {},
  handleNext: () => {},
  handleSkip: () => {},
  handleReset: () => {},
  isStepSkipped: (step: number) => false,
  isStepOptional: (step: number) => false,
  getActiveStepId: () => "",
})

WizardContext.displayName = "WizardContext"

// TODO - continue the TAnswer abstraction
export type AnswersContextProps = GenericAnswersContext<number> //other answers contexts should be added here

type Props = {
  steps: Step[]
  AnswersContext: Context<AnswersContextProps>
  onComplete?: (
    { surveySlug, userSlug }: OnCompleteProps,
    answers: Answers<number>
  ) => Promise<void>
  children?: React.ReactNode
}

const WizardContextProvider: FC<Props> = ({ steps = [], AnswersContext, onComplete, children }) => {
  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [skipped, setSkipped] = React.useState<Set<unknown>>(new Set())
  const [completed, setCompleted] = useState<boolean>(false)
  const [errorOnComplete, setErrorOnComplete] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isToCAndGDPRChecked, setIsToCAndGDPRChecked] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState<string>("")
  const [initUrlStep, setInitUrlStep] = useState<number | undefined>()

  const { getAnswers, reset: resetAnswersContext } = useContext<AnswersContextProps>(AnswersContext)
  const participationState = useContext<{
    surveySlug: string
    userSlug: string
  }>(ParticipationContext)
  const previousStep = useRef<number>(-1)
  const activeStepId: string = steps[activeStep].id ?? ""

  useEffect(() => {
    const stepParam = searchParams.get("step")
    const stepNumber = stepParam ? parseInt(stepParam) : 0
    // Save the step number but don't activate it yet as answers are not loaded and could be invalid
    setInitUrlStep(stepNumber)
    setToken(searchParams.get("token") || "")

    const checkedToCAndGDPR = surveyTempStorage.getGdprValue()
    if (checkedToCAndGDPR !== null) {
      setIsToCAndGDPRChecked(JSON.parse(checkedToCAndGDPR))
    } else {
      surveyTempStorage.setGdprValue(isToCAndGDPRChecked)
    }
  }, [])

  useEffect(() => {
    if (initUrlStep === undefined) return

    const answers = getAnswers()
    const answersLength = answers ? Object.keys(answers).length : 0

    // Make sure we don't go to a step which the user haven't reached yet
    const allowedStepNumber = Math.min(answersLength, initUrlStep)
    setActiveStep(allowedStepNumber)

    // Sync the url with the fallback step
    if (answersLength < initUrlStep) {
      setSearchParams({ ...searchParams, step: allowedStepNumber.toString() })
    }

    // Turn off this effect
    setInitUrlStep(undefined)
  }, [setSearchParams, getAnswers, searchParams, initUrlStep])

  // Sync the URL with the new step
  useEffect(() => {
    previousStep.current = activeStep
    setSearchParams({ step: activeStep.toString(), token: token })
  }, [activeStep, searchParams, setSearchParams, token])

  const isStepOptional = (step: number) => false //step === 1;
  const isStepSkipped = (step: number) => skipped.has(step)

  const handleNext = () => {
    if (isToCAndGDPRChecked) {
      surveyTempStorage.setGdprValue(isToCAndGDPRChecked)
      setErrorMessage("")
    } else {
      setErrorMessage("You must agree with the terms in order to continue")
      return
    }
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    // if there is no step validation - automatically decide it's valid
    const currentStep: Step = steps[activeStep]
    const { isValid, error } = currentStep.validate?.(getAnswers(), currentStep.questions) ?? {
      isValid: false,
      error: "No answer available",
    }

    if (isValid) {
      setErrorMessage("")
    } else {
      setErrorMessage(error)
      return
    }

    // Execute onComplete if we're moving towards the final step
    if (activeStep + 2 === steps.length) {
      handleComplete(() => setActiveStep((prevActiveStep) => prevActiveStep + 1))
    } else if (activeStep + 1 < steps.length) {
      // Make sure there is a next step before loading it
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    setSkipped(newSkipped)
    scrollToTop()
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    scrollToTop()
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setCompleted(false)
    setActiveStep(0)
    resetAnswersContext()
    setIsToCAndGDPRChecked(false)
    surveyTempStorage.deleteAll()
  }

  const handleComplete = (callback: () => void) => {
    const answers = getAnswers()
    onComplete?.(participationState, answers)
      .then(() => {
        setCompleted(true)
        surveyTempStorage.deleteAll()
        callback()
      })
      .catch((error) => {
        setErrorOnComplete(true)
        console.error("Error submitting answers", error)
      })
  }

  const getActiveStepId = () => steps[activeStep].id

  const contextObj: WizardContextProps = {
    steps,
    activeStep,
    activeStepId,
    previousStep: previousStep.current,
    skipped,
    errorMessage,
    completed,
    errorOnComplete,
    isToCAndGDPRChecked,
    setIsToCAndGDPRChecked,
    handleBack,
    handleNext,
    handleSkip,
    handleReset,
    isStepSkipped,
    isStepOptional,
    getActiveStepId,
  }

  return <WizardContext.Provider value={contextObj}>{children}</WizardContext.Provider>
}

export default WizardContextProvider
