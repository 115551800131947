import React, { createElement, FC } from "react"
import { ArrayField, ReferenceManyField, SingleFieldList, useRecordContext } from "react-admin"
import { surveyConfigs, SurveyTypeEnum } from "../../constants"
import { SurveyInstanceRecord } from "../../types"
import { parseSurveyMapId } from "../../utils"
import MatrixScaleResults from "./MatrixScaleResults"
import { OcaiResults } from "./OcaiResults"
import ScaleResults from "./ScaleResults"
import { SliderResults } from "./SliderResults"
import { TextboxResults } from "./TextboxResults"

const ResultComponentsMap: Record<SurveyTypeEnum, React.FunctionComponent> = {
  1: ScaleResults,
  2: SliderResults,
  3: TextboxResults,
  4: MatrixScaleResults,
  5: OcaiResults,
}

export const SurveyResultsField: FC<{ membersIds?: number[] }> = ({ membersIds = [] }) => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const surveyInstanceId: number = record?.id ?? -1
  const { typeId } = parseSurveyMapId(record?.surveyMapId)

  if (!typeId) return <></>

  const { resultsPath } = surveyConfigs[typeId]

  return (
    // Here we always expect a single result but in order to allow the filtering by memberId
    // we use the reference many component
    <ReferenceManyField
      record={{ id: surveyInstanceId }}
      target="surveyInstanceId"
      reference={`${resultsPath}`}
      filter={{ membersIds }}
    >
      <SingleFieldList linkType={false} sx={{ display: "block" }}>
        <ArrayField source="chapters">
          {createElement(ResultComponentsMap[typeId]) ?? <div>Unknown Survey Type</div>}
        </ArrayField>
      </SingleFieldList>
    </ReferenceManyField>
  )
}
