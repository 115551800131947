import React, { FC, useState } from "react"
import {
  Form,
  ReferenceManyField,
  SelectInput,
  useRecordContext,
  WithListContext,
} from "react-admin"
import { resourcesMap } from "../../../constants"
import { SurveyInstanceRecord } from "../../../types"
import { SurveyResultsField } from "../SurveyResultsField"

export const ByTeamResultsTabContent: FC = () => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const [selectedTeamId, setSelectedTeamId] = useState()

  if (record.teamId) return <div>The survey is already assigned to a team</div>

  return (
    <ReferenceManyField
      label={false}
      target="companyId"
      reference={resourcesMap.teams.basePath}
      // Passing fake record to allow filtering by companyId as a main record id
      record={{ id: record.companyId }}
    >
      <WithListContext
        render={({ data }) => {
          return (
            <Form>
              <SelectInput
                defaultValue={selectedTeamId}
                onChange={(e) => setSelectedTeamId(e.target.value)}
                choices={data ?? []}
                optionText="name"
                source="id"
                label="Team Name"
              />
              {selectedTeamId ? (
                <SurveyResultsField
                  membersIds={data.find((x) => x.id === selectedTeamId)?.membersIds}
                />
              ) : (
                <div>Select a team to view their results</div>
              )}
            </Form>
          )
        }}
      />
    </ReferenceManyField>
  )
}
