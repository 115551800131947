import { ChartOptions, TooltipItem } from "chart.js"
import React from "react"
import { RaRecord, useRecordContext } from "react-admin"
import { Bubble } from "react-chartjs-2"

const getYAxisLabel = (value: any, index: number, record: RaRecord, labelKey: string) => {
  if (index > 0) return ""
  return record[labelKey].match(/.{1,22}(?=\s|$)|\S+$/g) // this wraps the text on new line at 20 characters
}

const getOptions = (record: RaRecord): ChartOptions<"bubble"> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: true,
      text: record.text,
      align: "start",
      font: { size: 14 },
      padding: 20,
    },
    tooltip: {
      callbacks: {
        label: (item: TooltipItem<"bubble">) => item.parsed.x.toString(),
      },
    },
  },
  scales: {
    x: {
      ticks: {
        stepSize: 5,
      },
      min: 1,
      max: 100,
    },
    y: {
      ticks: {
        stepSize: 1,
        callback: (value, index) => getYAxisLabel(value, index, record, "leftLabel"),
      },
      position: "left",
      min: 1,
      max: 2,
      offset: true,
    },
    y2: {
      ticks: {
        stepSize: 1,
        callback: (value, index) => getYAxisLabel(value, index, record, "rightLabel"),
      },
      position: "right",
      min: 1,
      max: 2,
      offset: true,
    },
  },
})

const BubbleChartField = () => {
  const record = useRecordContext()

  if (record.answers === null) {
    return <>No Results</>
  }

  const valueCount: Record<number, number> = {}

  const answers = record.answers.map((value: number) => {
    valueCount[value] = (valueCount[value] || 0) + 1
    return { x: value, y: 1 + 0.2 * (valueCount[value] - 1), r: 6 }
  })

  const data = {
    datasets: [
      {
        labels: Array.from({ length: 100 }, (v, i) => i),
        data: answers,
        borderColor: "#00bd9c",
        backgroundColor: "rgba(0, 189, 156, 0.5)",
      },
    ],
  }

  return (
    <div style={{ display: "block", height: "350px", width: "700px" }}>
      <Bubble data={data} options={getOptions(record)} />
    </div>
  )
}

export default BubbleChartField
