import React, { FC } from "react"
import { ArrayField, Datagrid, FunctionField, TextField, useRecordContext } from "react-admin"
import { ColumnLabel } from "../../types/matrix-survey"
import LineChartField from "./charts/line-chart-field/LineChartField"

const MatrixScaleResults: FC = () => {
  const record = useRecordContext()

  const columnLabels = new Map<number, string>()

  record.columnLabels.forEach((columnLabel: ColumnLabel) =>
    columnLabels.set(columnLabel.weight, columnLabel.label)
  )

  return (
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="chapter"
        render={(partialRecord: any) =>
          `${record["chapters"].findIndex((chapter: any) => chapter.id === partialRecord.id) + 1}. ${partialRecord.name}`
        }
      />
      <ArrayField source="questions">
        <Datagrid
          bulkActionButtons={false}
          rowSx={() => ({
            display: "block", // applies to the <tr> element
            "& td:nth-child(2)": {
              // applies to the line chart <td> field
              display: "block",
            },
          })}
        >
          <TextField source="text" />
          <LineChartField yColumnsLabels={columnLabels} />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  )
}

export default MatrixScaleResults
