import { lighten } from "@mui/material/styles"
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js"
import React, { FC } from "react"
import { RaRecord, useRecordContext } from "react-admin"
import { PolarArea } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  RadialLinearScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
)

type PolarAreaChartProps = {
  lowerLimit: number
  upperLimit: number
  getLabels: (record: RaRecord) => Array<string>
  getIndex?: (record: RaRecord) => number
}

type ChapterAnswers = {
  answers: number[]
  averageScore: number
  chapterId: number
  id: number
  isRequired: boolean
  text: string
}

const colors = [
  "#132c4c", // CP Blue
  "#fec332", // Yellow
  "#265a9d", // Blue
  "#00bd9c", // CP Green
  "#f15a24", // Orange
  "#5c3c92", // Purple
]

const getColors = (index: number) => {
  const normalizedIndex = index % colors.length
  const chosenColor = colors[normalizedIndex]
  return new Array(5).fill(0).map((_, i) => lighten(chosenColor, i * 0.2))
}

const PolarAreaChart: FC<PolarAreaChartProps> = ({
  lowerLimit,
  upperLimit,
  getLabels,
  getIndex,
}) => {
  const record = useRecordContext()
  const allAnswers = record.questions.map((q: ChapterAnswers) => q.averageScore)

  const data = {
    labels: getLabels(record),
    datasets: [
      {
        data: allAnswers,
        backgroundColor: getColors(getIndex?.(record) ?? 0),
        borderWidth: 1,
      },
    ],
  }

  const options = {
    scale: {
      ticks: {
        beginAtZero: true,
      },
      // if the lower limit is set as a min, answers with the lowest value
      // does not appear on the chart because they are in the center and look like missing
      min: lowerLimit - 1,
      max: upperLimit,
    },
    legend: {
      position: "right",
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <div style={{ display: "block", height: "300px", width: "600px" }}>
      <PolarArea data={data} options={options} />
    </div>
  )
}

export default PolarAreaChart
