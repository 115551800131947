import React, { FC } from "react"
import { ArrayInput, BooleanInput, required, SimpleFormIterator, TextInput } from "react-admin"
import { Box } from "@mui/material"
import { BaseSurveyForm } from "../BaseSurveyForm"
import { ChapterArrayInput } from "../ChapterArrayInput"

const columnsFieldStyle = { flexBasis: "70%" }
const weightFieldStyle = { flexBasis: "20%" }

const initialColumnLabelsState = [
  { label: "Strongly Disagree", weight: -2 },
  { label: "Disagree", weight: -1 },
  { label: "Neutral", weight: 0 },
  { label: "Agree", weight: 1 },
  { label: "Strongly Agree", weight: 2 },
]

const MatrixSurveyForm: FC = () => {
  const ColumnLabels = () => (
    <Box display={{ xs: "block", sm: "flex", width: "50%" }}>
      <ArrayInput source="columnLabels" defaultValue={initialColumnLabelsState}>
        <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`} inline>
          <TextInput source="label" label="Columns" validate={required()} sx={columnsFieldStyle} />
          <TextInput source="weight" label="Weight" validate={required()} sx={weightFieldStyle} />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  )

  const SurveyDitails = () => <ColumnLabels />

  return (
    <BaseSurveyForm warnWhenUnsavedChanges details={<SurveyDitails />}>
      <ChapterArrayInput>
        {/* Questions */}
        <ArrayInput source="questions" label="Questions">
          <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`} disableReordering>
            <TextInput source="text" label="Text" validate={required()} fullWidth />
            <BooleanInput disabled source="isRequired" label="Required" defaultValue={true} />
            {/* Statements */}
            <ArrayInput source="statements" label="Statements">
              <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`}>
                <TextInput source="text" label="Text" validate={required()} fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </ChapterArrayInput>
    </BaseSurveyForm>
  )
}

export default MatrixSurveyForm
