import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import React, { FC, useState } from "react"
import { useTranslate } from "react-admin"
import SurveyInput from "../../common/inputs/SurveyInput"

const styles = {
  container: {
    cursor: "pointer",
    flexDirection: "row",
  },
}

type SliderScaleInputProps = {
  questionId: number
  onClick: (questionId: number, value: number | undefined) => void
  leftLabel: string
  centerLabel?: string
  rightLabel: string
  startingPoint: number
  lowerLimit: number
  upperLimit: number
  selectedValue?: number
}

const InputElement: FC<SliderScaleInputProps> = ({
  questionId,
  leftLabel,
  rightLabel,
  centerLabel,
  startingPoint,
  lowerLimit,
  upperLimit,
  selectedValue,
  onClick,
}) => {
  const translate = useTranslate()
  const [error, setError] = useState<string | null>(null)

  const handleSliderChange = (_: Event, value: number | number[]) => {
    if (value !== startingPoint) {
      setError(null)
      onClick(questionId, value as number)
    } else {
      setError(translate("inputs.slider.errorInitialValue"))
      onClick(questionId, undefined)
    }
  }

  return (
    <div>
      <Box mt={2} gap={2} flexDirection="row" textAlign="center">
        <Box flexDirection="row" display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="body2" sx={{ width: "45%" }}>
            {leftLabel}
          </Typography>
          <Typography variant="body2">{centerLabel}</Typography>
          <Typography variant="body2" sx={{ width: "45%" }}>
            {rightLabel}
          </Typography>
        </Box>
        <Slider
          color="primary"
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="discrete-slider"
          min={lowerLimit}
          max={upperLimit}
          value={selectedValue ?? startingPoint}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </div>
  )
}

const SliderScaleInput: FC<Omit<SliderScaleInputProps, "onClick" | "lowerLimit" | "upperLimit">> = (
  props
) => {
  return (
    <Box sx={styles.container}>
      <SurveyInput
        {...props}
        type="slider"
        InputElement={InputElement}
        lowerLimit={0}
        upperLimit={100}
      />
    </Box>
  )
}

export default SliderScaleInput
