import React, { FC, useState } from "react"
import {
  Form,
  ReferenceArrayField,
  SelectInput,
  useRecordContext,
  WithListContext,
} from "react-admin"
import { resourcesMap } from "../../../constants"
import { SurveyInstanceRecord } from "../../../types"
import { SurveyResultsField } from "../SurveyResultsField"

export const IndividualResultsTabContent: FC = () => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const [selectedMemberId, setSelectedMemberId] = useState()

  if (record.isAnonymous)
    return <div>The survey is marked as anonymous. Individual results are not accessible</div>

  return (
    <ReferenceArrayField
      label={false}
      source="membersIds"
      reference={resourcesMap.employees.basePath}
    >
      <WithListContext
        render={({ data }) => (
          <Form>
            <SelectInput
              defaultValue={selectedMemberId}
              onChange={(e) => setSelectedMemberId(e.target.value)}
              choices={data}
              optionText="fullName"
              source="employeeId"
              label="Employee"
            />
            {selectedMemberId ? (
              <SurveyResultsField membersIds={[selectedMemberId]} />
            ) : (
              <div>Select an employee to view their results</div>
            )}
          </Form>
        )}
      />
    </ReferenceArrayField>
  )
}
