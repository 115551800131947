import { HttpOptions, httpPost } from "../ra-providers/dataProvider"

const INVITATIONS_ENDPOINT = "/Invitations/CreateInstanceInvites"

const sendInstanceInvitations = async (surveyInstanceId: number, options?: HttpOptions) => {
  const result = await httpPost(
    `${INVITATIONS_ENDPOINT}`,
    {
      surveyInstanceId,
    },
    options
  )

  return result
}

export default {
  sendInstanceInvitations,
}
