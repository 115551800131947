import { HttpOptions, httpPost } from "../ra-providers/dataProvider"

const NOTIFICATIONS_ENDPOINT = "/notifications"

type RemindMemberPayload = {
  surveyInstanceId: number
  memberId: number
}

const remindMember = async (
  { surveyInstanceId, memberId }: RemindMemberPayload,
  options?: HttpOptions
): Promise<string> => {
  const result = await httpPost(
    `${NOTIFICATIONS_ENDPOINT}/RemindMember`,
    {
      surveyInstanceId,
      memberId,
    },
    options
  )

  return result
}

const remindAllInstanceMembers = async (
  surveyInstanceId: number,
  options?: HttpOptions
): Promise<string> => {
  const result = await httpPost(
    `${NOTIFICATIONS_ENDPOINT}/RemindAllInstanceMembers`,
    {
      surveyInstanceId,
    },
    options
  )

  return result
}

export default {
  remindMember,
  remindAllInstanceMembers,
}
