import EmailIcon from "@mui/icons-material/Email"
import React, { FC, useCallback } from "react"
import { useNotify, useRecordContext } from "react-admin"
import invitationService from "../../../services/invitation-service"
import { SurveyInstanceRecord } from "../../../types"
import { AsyncButton } from "../../_design"

const SendEmailsButton: FC = () => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const notify = useNotify()

  const onClick = useCallback(async () => {
    await invitationService.sendInstanceInvitations(record?.id ?? -1, {
      onSuccess: () => {
        notify("Survey invitations sent successfully", { type: "success" })
      },
      onFailure: (error) => {
        notify(error.body ?? "Error sending invitations", { type: "error" })
      },
    })
  }, [record, notify])

  return (
    <AsyncButton
      disabled={record.invitedTotal > 0}
      color="secondary"
      label="Send Emails"
      dialogTitle="Sending all invitations"
      startIcon={<EmailIcon />}
      httpCall={onClick}
    />
  )
}

export default SendEmailsButton
