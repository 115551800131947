import { AppBar, Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { ThemeProvider } from "../../../ra-providers/contexts"

const styles = {
  appBar: {
    height: "48px",
    flex: 1,
    display: "flex",
    color: "white",
    justifyContent: "center",
    paddingLeft: "15px",
    backgroundColor: "secondary.main",
  },
  container: {
    marginTop: "48px",
  },
}

type NotAdminLayoutProps = React.PropsWithChildren<{ title?: string }>

const NotAdminLayout: FC<NotAdminLayoutProps> = ({ title, children }) => (
  <>
    <AppBar sx={styles.appBar}>
      <Typography variant="h5" component="h3">
        {title ?? `Culture & People`}
      </Typography>
    </AppBar>
    <Box sx={styles.container}>{children}</Box>
  </>
)

export default (props: NotAdminLayoutProps) => (
  <ThemeProvider>
    <NotAdminLayout {...props} />
  </ThemeProvider>
)
