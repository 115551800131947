import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import React, { FC, useContext, useEffect } from "react"
import { surveyTempStorage } from "../../../utils/common-utils"
import { WizardContext } from "../../wizard/context/WizardContextProvider"

type Props = {
  intro?: string
}

export const IntroWithToCAndGDPRCheckbox: FC<Props> = ({ intro }) => {
  const { isToCAndGDPRChecked, setIsToCAndGDPRChecked } = useContext(WizardContext)

  useEffect(() => {
    if (surveyTempStorage.getGdprValue()) {
      setIsToCAndGDPRChecked(JSON.parse(surveyTempStorage.getGdprValue() || "false"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = () => {
    setIsToCAndGDPRChecked((prevState) => {
      return !prevState
    })
  }

  return (
    <div>
      {<p>{intro ? intro : ""}</p>}
      <div style={{ marginTop: "8rem" }}>
        <FormControlLabel
          control={
            <Checkbox required onChange={handleChange} checked={Boolean(isToCAndGDPRChecked)} />
          }
          label={
            <span>
              I have read and agree to the <a href="/terms-and-conditions">Terms and Conditions</a>,{" "}
              <a href="/privacy-policy">Privacy Policy</a> , and Cookie Policy of Culture & People.
              By checking this box, I acknowledge that I have fully understood and accepted the
              terms outlined in these documents.
            </span>
          }
        />
      </div>
    </div>
  )
}
