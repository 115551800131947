import React, { FC } from "react"
import { Datagrid, DeleteButton, EditButton, List, NumberField, TextField } from "../../_design"

const ScaleSurveyList: FC = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="scaleType" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export default ScaleSurveyList
