import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "@mui/material"
import React, { FC } from "react"
import { FieldValues } from "react-hook-form"
import { number, object } from "yup"
import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "../../_design"
import { BaseSurveyForm } from "../BaseSurveyForm"
import { ChapterArrayInput } from "../ChapterArrayInput"
import { useWatch } from "react-hook-form"
import { required } from "react-admin"

const schema = object<FieldValues>({
  lowerLimit: number()
    .integer()
    .required()
    .test(
      "is-lower",
      "Lower limit must be lower than Upper limit",
      function (value: number): boolean {
        return value < this.parent.upperLimit
      }
    ),
  upperLimit: number()
    .integer()
    .required()
    .test("is-higher", "Upper limit must be higher than Lower limit", function (value: number) {
      return value > this.parent.lowerLimit
    }),
})

const LocaleSelectInput = () => {
  const type = useWatch({ name: "scaleType" })
  return (
    <>
      {type === "Likert" && (
        <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
          <SelectInput
            source="locale"
            choices={[
              { id: "EN", name: "English" },
              { id: "BG", name: "Bulgarian" },
            ]}
            fullWidth
            validate={required()}
          />
        </Box>
      )}
    </>
  )
}

const ScaleSurveyForm: FC = () => {
  return (
    <BaseSurveyForm
      resolver={yupResolver(schema)}
      details={
        <>
          <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
            <SelectInput
              source="scaleType"
              choices={[
                { id: "Numeric", name: "Numeric" },
                { id: "Likert", name: "Likert" },
              ]}
              fullWidth
              validate={required()}
            />
          </Box>
          <LocaleSelectInput />
          <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <NumberInput source="lowerLimit" validate={required()} />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <NumberInput source="upperLimit" validate={required()} />
            </Box>
          </Box>
        </>
      }
    >
      <ChapterArrayInput>
        <ArrayInput source="questions" label="Questions">
          <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`}>
            <TextInput source="text" label="Text" validate={required()} multiline fullWidth />
            <BooleanInput source="isRequired" label="Required" defaultValue={true} />
          </SimpleFormIterator>
        </ArrayInput>
      </ChapterArrayInput>
    </BaseSurveyForm>
  )
}

export default ScaleSurveyForm
