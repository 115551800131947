import React, { FC } from "react"
import { SimpleList } from "react-admin"
import { ArrayField, Datagrid, TextField } from "../_design"

export const TextboxResults: FC = () => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <ArrayField source="questions">
        <Datagrid bulkActionButtons={false}>
          <TextField source="text" />
          <ArrayField source="answers">
            <SimpleList primaryText={(record) => record} />
          </ArrayField>
        </Datagrid>
      </ArrayField>
    </Datagrid>
  )
}
