import CircularProgress from "@mui/material/CircularProgress"
import React, { useCallback, useState } from "react"
import { Button, ButtonProps, Confirm, useRefresh } from "react-admin"

type AsyncButtonProps = {
  httpCall: () => Promise<any>
  dialogTitle?: string
} & ButtonProps

export const AsyncButton = ({ httpCall, dialogTitle, disabled, ...props }: AsyncButtonProps) => {
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const httpAction = useCallback(
    async (e) => {
      setLoading(true)
      handleDialogClose()

      httpCall().finally(() => {
        refresh()
        setLoading(false)
      })
    },
    [refresh, httpCall]
  )

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleDialogClose = () => setOpen(false)

  return (
    <>
      <Button
        {...props}
        disabled={disabled ?? loading}
        startIcon={
          loading ? <CircularProgress variant="indeterminate" size={20} /> : props.startIcon
        }
        onClick={onClick}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title={dialogTitle}
        content="Are you sure?"
        onConfirm={httpAction}
        onClose={handleDialogClose}
      />
    </>
  )
}
