import EmailIcon from "@mui/icons-material/Email"
import Box from "@mui/material/Box"
import React, { FC, useState } from "react"
import {
  Form,
  ReferenceArrayField,
  SelectInput,
  useNotify,
  useRecordContext,
  WithListContext,
} from "react-admin"
import { resourcesMap } from "../../constants"
import { notificationService } from "../../services"
import { SurveyInstanceRecord } from "../../types"
import { AsyncButton } from "../_design"

export const RemindMemberInput: FC<{ source: string }> = () => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const notify = useNotify()
  const [selectedMemberId, setSelectedMemberId] = useState<undefined | number>()

  const httpCall = async () => {
    return notificationService.remindMember(
      {
        surveyInstanceId: record?.id ?? -1,
        memberId: selectedMemberId ?? -1,
      },
      {
        onSuccess: (res) => {
          notify(res ?? "Reminder sent successfully", { type: "success" })
        },
        onFailure: (error) => {
          notify(error.body ?? "Error sending reminder", { type: "error" })
        },
      }
    )
  }

  return (
    <ReferenceArrayField
      label={false}
      source="membersIds"
      reference={resourcesMap.employees.basePath}
    >
      <WithListContext
        render={({ data }) =>
          data?.length > 0 && (
            <Form>
              <Box display="flex" alignContent="baseline">
                <SelectInput
                  // defaultValue={selectedMemberId}
                  onChange={(e) => setSelectedMemberId(e.target.value)}
                  choices={data}
                  optionText="fullName"
                  source="employeeId"
                  label="Employee"
                />
                {selectedMemberId ? (
                  <AsyncButton
                    sx={{ alignSelf: "center", ml: 2 }}
                    startIcon={<EmailIcon />}
                    variant="contained"
                    color="secondary"
                    label="Send Reminder"
                    dialogTitle={`Sending email reminder to ${data?.find((x) => x.id === selectedMemberId)?.fullName ?? ""}`}
                    httpCall={httpCall}
                  />
                ) : null}
              </Box>
            </Form>
          )
        }
      />
    </ReferenceArrayField>
  )
}
