import { MatrixAnswers } from "../components/participation/context"
import { Answers } from "../types"

export const isSmallScreen = (): boolean => window.matchMedia("(max-width: 40em)").matches

export const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

export const convertDateToDDMMYYYY = (date: Date) =>
  new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })

const saveSurveyAnswersToLocalStorage = <TAnswer = unknown>(answers: TAnswer) => {
  if (areAnswersMap(answers)) {
    localStorage.setItem(getAnswersKey(), stringifyMapAnswers(answers as Answers<MatrixAnswers>))
  } else {
    localStorage.setItem(getAnswersKey(), JSON.stringify(answers))
  }
}

const getSurveyAnswersFromLocalStorage = () => {
  const storedAnswers = localStorage.getItem(getAnswersKey())
  if (isJSONAnswersMap(storedAnswers)) {
    return storedAnswers ? tryParseMapAnswers(storedAnswers) : null
  } else {
    return storedAnswers ? JSON.parse(storedAnswers) : null
  }
}

const tryParseMapAnswers = (answers: string | null) => {
  if (!answers) {
    return null
  }

  try {
    let answersMap: Map<number, string> = new Map(JSON.parse(answers))

    if (answersMap !== undefined) {
      let parsedAnswers: Answers<MatrixAnswers> = {}
      answersMap.forEach((value, key) => {
        parsedAnswers = {
          ...parsedAnswers,
          [Number(key)]: new Map(JSON.parse(value || "")),
        }
      })

      return parsedAnswers
    }

    return null
  } catch (e) {
    return null
  }
}

const areAnswersMap = (answers: any) => {
  if (answers === undefined || answers === null) {
    return false
  }

  const firstKey = Object.keys(answers)[0]?.toString() || ""

  return typeof answers === "object" && firstKey !== "" && answers[firstKey] instanceof Map
}

const isJSONAnswersMap = (answersJSON: string | null) => {
  const parsedAnswers: any = tryParseMapAnswers(answersJSON || "")
  return areAnswersMap(parsedAnswers)
}

const stringifyMapAnswers = (answers: Answers<MatrixAnswers>): string => {
  let answersMap = new Map<number, string>()

  for (const key in answers) {
    answersMap.set(Number(key), JSON.stringify(Array.from(answers[key].entries())))
  }

  return JSON.stringify(Array.from(answersMap.entries()))
}

const LS_KEYS = {
  GDPR: "ToCnGDPR",
  ANSWERS: "answers",
}

const getCurrentSurveyUniqueLsKey = () => window.location.pathname.replace("/participate/", "")
const getAnswersKey = () => `${LS_KEYS.ANSWERS}-${getCurrentSurveyUniqueLsKey()}`
const getGdprKey = () => `${LS_KEYS.GDPR}-${getCurrentSurveyUniqueLsKey()}`

const setGdprValue = (value: boolean | null) =>
  localStorage.setItem(getGdprKey(), JSON.stringify(value ?? false))

const deleteTempStorageForCurrentSurvey = () => {
  localStorage.removeItem(getAnswersKey())
  localStorage.removeItem(getGdprKey())
}

export const surveyTempStorage = {
  deleteAll: deleteTempStorageForCurrentSurvey,
  getGdprValue: () => localStorage.getItem(getGdprKey()),
  setGdprValue,
  getAnswersValue: getSurveyAnswersFromLocalStorage,
  setAnswersValue: saveSurveyAnswersToLocalStorage,
}
